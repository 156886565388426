<template>
    <v-card>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="mini"
      permanent
      bottom
    >
      <v-list-item class="px-2">
        <v-list-item-avatar @click.stop="mini = !mini">
          <v-icon>mdi-menu</v-icon>
        </v-list-item-avatar>

        <v-list-item-title></v-list-item-title>

        <v-btn
          icon
          @click.stop="mini = !mini"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-list-item class="px-2" v-for="(item,i) in items" :key="i">
          <v-list-item-avatar color="grey lighten-3" @click="hideShow(item)">
            <v-icon v-if="item.icon == ''">mdi-truck</v-icon>
            <v-img v-else :src="item.icon"/>
          </v-list-item-avatar>
      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
          <v-list-item-title 
          v-bind="attrs"
          v-on="on">{{item.name}}</v-list-item-title>
          <v-btn
          icon
            @click="hideShow(item)"
            >
            <v-icon v-if="item.visible">mdi-eye</v-icon>
            <v-icon v-else>mdi-eye-off</v-icon>
        </v-btn>
      </template>
      <span>{{item.uniqueId}}<br />{{getDateString(item.creado)}}</span>
      </v-tooltip>
      </v-list-item>
    </v-navigation-drawer>
  </v-card>
</template>

<script>

export default {
    name:'mapDevices',
    props:{
      markers:{
        type: Array,
        default: ()=>[]
      }
    },
    data(){
        return {
        drawer: true,
        mini: true,
        items: []
        };
    },
    watch:{
      markers: function(newVal){
        //console.log('Prop changed: ', newVal, ' | was: ', oldVal);
        this.items = newVal.map((o)=> { return {
          name: o.m.name, 
          creado: o.m.creado,
          visible:o.m.visible,
          id: o.m.deviceid,
          uniqueId: o.m.uniqueid,
          active: o.m.active
          ,icon: o.m.icon
          }
          });
      }
    },
    created(){
      /*
        this.getDevices().then((res) => {
            this.items = res.data.data;

            for(const it of this.items) {
                it.visible = true;
 }
            console.log(this.items);
        })
        .catch((err)=>{
            console.log(err);
        });
        */
    },
    methods:{
        hideShow(item){
            if(item.visible)
                this.$emit('hideMark',item.id);
            else
                this.$emit('showMark',item.id);

            this.items = this.items.map((i)=> {if(i.id== item.id)i.visible = !i.visible; return i;})
        },
        getDateString(date){
            if(date == undefined)
                return "";
            var dformat = this.$func.getFullDateString(date);

            return dformat;
        },
    }
}
</script>

<style>

</style>